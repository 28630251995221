import React, { FC, useState, ReactNode } from "react"
import isEmpty from "lodash/isEmpty"
import { Chapter } from "../../services/chapters"
import Grid from "../grid"
import styles from "./table-of-contents.module.css"

interface TableOfContentsProps<T = any> {
  chapters: Chapter[]
  renderCard: (item: T, idx: number, list: T[]) => ReactNode
}

const TableOfContents: FC<TableOfContentsProps> = ({
  chapters,
  renderCard,
}) => {
  const [openAccIdx, setOpenAccIdx] = useState([-1, -1])

  const handleToggle = (lvls: number[]) => {
    setOpenAccIdx(isOpen(lvls) ? [-1, -1] : lvls)
  }

  const isOpen = (lvls: number[]) =>
    lvls[0] === openAccIdx[0] && lvls[1] === openAccIdx[1]

  return (
    <ol type="I" className={styles.component}>
      {chapters.map((chapter, idxLvl1) => (
        <li key={chapter.title}>
          {chapter.title}
          <ul className={styles.subchapters}>
            {chapter.children?.map((subchapter, idxLvl2) => (
              <li key={subchapter.title}>
                {isEmpty(subchapter.assets) ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: subchapterName([idxLvl1, idxLvl2], subchapter),
                    }}
                  />
                ) : (
                  <a
                    className={styles.contentful}
                    onClick={handleToggle.bind(null, [idxLvl1, idxLvl2])}
                    dangerouslySetInnerHTML={{
                      __html: subchapterName([idxLvl1, idxLvl2], subchapter),
                    }}
                  />
                )}
                {subchapter.assets /*&& isOpen([idxLvl1, idxLvl2])*/ && (
                  <Grid
                    list={subchapter.assets}
                    renderItem={renderCard}
                    isOpen={isOpen([idxLvl1, idxLvl2])}
                  />
                )}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ol>
  )
}

const subchapterName = (lvls: number[], chapter: Chapter) =>
  `${lvls.map(v => v + 1).join(".")} ${chapter.title}`

export default TableOfContents
