import { useEffect } from "react"

// https://usehooks.com/useEventListener/
// https://stackoverflow.com/questions/29069639/listen-to-keypress-for-document-in-reactjs

export enum KeyboardEventType {
  keypress = "keypress",
  keydown = "keydown",
  keyup = "keyup",
}

type UseKeyEventHook = (
  keyEvent: KeyboardEventType,
  handler: (evt: KeyboardEvent) => void
) => void
const useKeyEvent: UseKeyEventHook = (keyEvent, handler) => {
  useEffect(() => {
    document.addEventListener(keyEvent, handler)
    return () => {
      document.removeEventListener(keyEvent, handler)
    }
  }, [keyEvent, handler])
}

export default useKeyEvent
