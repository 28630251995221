import React, { FC } from "react"
import Img from "react-image"
import { Asset, AssetType } from "../../services/chapters"
import { API_BASE_URL } from "../../constants/api"
import IconPrev from "../../images/prev.inline.svg"
import IconNext from "../../images/next.inline.svg"
import IconClose from "../../images/cancel.inline.svg"
import styles from "./gallery.module.css"
import VideoPlayer from "../video-player"
import useKeyEvent, { KeyboardEventType } from "../../hooks/use-key-event"
import Spinner from "../spinner"

interface GalleryProps {
  galleryIndex: number
  galleryItems: Asset[]
  showPrev: () => void
  showNext: () => void
  hideGallery: () => void
}

const Gallery: FC<GalleryProps> = ({
  galleryIndex,
  galleryItems,
  showNext,
  showPrev,
  hideGallery,
}) => {
  const asset = galleryItems[galleryIndex]
  const isNavVisible = galleryItems && galleryItems.length > 1

  useKeyEvent(KeyboardEventType.keyup, (evt: KeyboardEvent) => {
    switch (evt.code) {
      case "ArrowRight":
        return showNext()
      case "ArrowLeft":
        return showPrev()
      case "Escape":
        return hideGallery()
    }
  })

  return (
    <div className={styles.component}>
      <div className={styles.backdrop} />
      <div className={styles.content}>
        <IconClose className={styles.closeBtn} onClick={hideGallery} />
        {isNavVisible && (
          <>
            <IconPrev className={styles.navBtnPrev} onClick={showPrev} />
            <IconNext className={styles.navBtnNext} onClick={showNext} />
          </>
        )}
        <figure className={styles.figure}>
          <div className={styles.visual}>
            {asset.type === AssetType.Image && (
              <Img
                src={`${API_BASE_URL}${galleryItems[galleryIndex].url}`}
                loader={<Spinner />}
              />
            )}
            {asset.type === AssetType.Video && (
              <VideoPlayer url={asset.url} controls />
            )}
          </div>
          <figcaption className={styles.description}>
            <h3>{asset.caption}</h3>
            <p>{asset.description}</p>
            <div>
              <ul>
                {asset.sources.map(source => (
                  <li key={source}>{source}</li>
                ))}
              </ul>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  )
}

export default Gallery
