import React, { FC, ReactNode, useRef } from "react"
import styles from "./grid.module.css"
import useResizeObserver from "../../hooks/use-resize-observer"

interface GridProps<T = any> {
  list: T[]
  renderItem: (item: T, idx: number, list: T[]) => ReactNode
  isOpen?: boolean
}

const Grid: FC<GridProps> = ({ list, renderItem, isOpen = false }) => {
  const ref = useRef(null)
  const dimensions = useResizeObserver(ref)
  const expansion = getExpansionHeight(isOpen, list, dimensions)
  return (
    <div
      ref={ref}
      className={styles.component}
      style={{
        maxHeight: `${expansion.height}px`,
        transitionDuration: `${expansion.duration}s`,
      }}
    >
      <ul className={styles.list}>
        {list.map((asset, idx) => (
          <li key={idx} className={styles.item}>
            {renderItem(asset, idx, list)}
          </li>
        ))}
      </ul>
    </div>
  )
}

const getExpansionHeight = (
  isOpen: boolean,
  list: any[],
  dimensions: DOMRectReadOnly | null
) => {
  if (!dimensions) {
    return { height: 0, duration: 0 }
  }
  const { width } = dimensions
  const cardsPerRow = width > 852 ? 3 : width > 492 ? 2 : 1
  const margin = 32
  const cardHeight = 390
  const height = cardHeight * Math.ceil(list.length / cardsPerRow) + margin
  return {
    height: isOpen ? height : 0,
    duration: height / 2000,
  }
}

export default Grid
