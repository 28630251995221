import React, { FC } from "react"
import get from "lodash/get"
import { Asset, AssetType } from "../../services/chapters"
import styles from "./card.module.css"
import { API_BASE_URL } from "../../constants/api"
import { getQueryParams } from "../../utils/misc"
import PlayIcon from "../../images/play.inline.svg"

interface CardProps {
  asset: Asset
  onClick: (asset: Asset) => void
}

const Card: FC<CardProps> = ({ asset, onClick }) => (
  <div className={styles.component} onClick={onClick.bind(null, asset)}>
    <Thumbnail asset={asset} />
    <h3 className={styles.caption}>{asset.caption}</h3>
  </div>
)

interface ThumbnailProps {
  asset: Asset
}

const Thumbnail: FC<ThumbnailProps> = ({ asset }) => {
  const imgUrl = getThumbnail(asset)
  if (!imgUrl) {
    return null
  }

  return (
    <div className={styles.thumbnail}>
      {asset.type === AssetType.Video && (
        <PlayIcon className={styles.playIcon} />
      )}
      <img src={imgUrl} />
    </div>
  )
}

const getThumbnail = (asset: Asset) => {
  if (asset.thumbnail) {
    return asset.thumbnail
  }
  switch (asset.type) {
    case AssetType.Image:
      return `${API_BASE_URL}${asset.url}`
    case AssetType.Video:
      return getVideoThumbnail(asset.url)
    default:
      return null
  }
}

const getVideoThumbnail = (url: string) => {
  if (url.includes("youtube")) {
    const params = getQueryParams(url)
    const videoId = get(params, "v")
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : null
  }
  return null
}

export default Card
