import React, { FC, useState, useEffect } from "react"
import Layout from "../components/layout"
import { checkIsLoggedIn } from "../services/auth"
import { navigate } from "gatsby"
import { Route } from "../constants/routes"
import SEO from "../components/seo"
import {
  fetchChapters,
  Chapter as ChapterType,
  Asset,
} from "../services/chapters"
import TableOfContents from "../components/table-of-contents"
import Card from "../components/card"
import Gallery from "../components/gallery"
import { isBrowser } from "../utils/misc"

const Chapter: FC = () => {
  if (!checkIsLoggedIn() && isBrowser()) {
    navigate(Route.Start)
    return null
  }

  const chapters = useChapters()
  const { showGallery, isGalleryVisible, ...galleryProps } = useGallery()

  return (
    <Layout>
      <SEO
        title="Inhalte Masterarbeit"
        htmlAttributes={{
          class: isGalleryVisible ? "no-overflow" : "",
        }}
      />
      <div className="content">
        <TableOfContents
          chapters={chapters}
          renderCard={(item, idx, list) => (
            <Card asset={item} onClick={showGallery.bind(null, idx, list)} />
          )}
        />
      </div>
      {isGalleryVisible && <Gallery {...galleryProps} />}
    </Layout>
  )
}

const useGallery = () => {
  const [isGalleryVisible, setIsGalleryVisible] = useState(false)
  const [galleryItems, setGalleryItems] = useState<Asset[]>([])
  const [galleryIndex, setGalleryIndex] = useState(0)

  const showGallery = (idx: number, list: Asset[]) => {
    setGalleryIndex(idx)
    setGalleryItems(list)
    setIsGalleryVisible(true)
  }

  const hideGallery = () => {
    setIsGalleryVisible(false)
  }

  const showNext = () => {
    const itemCount = galleryItems.length
    setGalleryIndex(idx => (idx < itemCount - 1 ? idx + 1 : 0))
  }

  const showPrev = () => {
    const itemCount = galleryItems.length
    setGalleryIndex(idx => (idx > 0 ? idx - 1 : itemCount - 1))
  }

  return {
    showGallery,
    hideGallery,
    showNext,
    showPrev,
    isGalleryVisible,
    galleryItems,
    galleryIndex,
  }
}

const useChapters = () => {
  const [isFetched, setIsFetched] = useState(false)
  const [chapters, setChapters] = useState<ChapterType[]>([])

  useEffect(() => {
    ;(async () => {
      if (!isFetched) {
        setChapters(await fetchChapters())
        setIsFetched(true)
      }
    })()
  }, [isFetched, chapters, setChapters])

  return chapters
}

export default Chapter
