import React, { FC } from "react"
import ReactPlayer, { ReactPlayerProps } from "react-player"
import styles from "./video-player.module.css"

const VideoPlayer: FC<ReactPlayerProps> = props => (
  <div className={styles.component}>
    <ReactPlayer
      {...props}
      className={styles.reactPlayer}
      width="100%"
      height="100%"
    />
  </div>
)

export default VideoPlayer
