import { API_BASE_URL } from "../constants/api"

export enum AssetType {
  Image = "image",
  Video = "video",
}

export interface Asset {
  url: string
  thumbnail?: string
  caption: string
  description: string
  type: AssetType
  sources: string[]
}

export interface Chapter {
  title: string
  assets?: Asset[]
  children?: Chapter[]
}

export const fetchChapters = async (): Promise<Chapter[]> => {
  try {
    const res = await fetch(`${API_BASE_URL}/assets/chapters.json`, {
      method: "GET",
      credentials: "include",
    })
    const chapters = await res.json()
    if (chapters.error) {
      return []
    }
    return chapters
  } catch (err) {
    return []
  }
}
